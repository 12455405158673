import { Swiper, Lazy, Pagination } from 'swiper';

export const mainSharesSlider = () => {
    Swiper.use([Lazy, Pagination]);
    new Swiper('.main-shares', {
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 30,
        preloadImages: false,
        lazy: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        // Responsive breakpoints
        breakpoints: {
            0:{
                slidesPerView:1
            },
            768:{
                slidesPerView:2
            },
            1025:{
                slidesPerView:3
            },
            1200:{
                slidesPerView:4
            }
        }
    });
}
