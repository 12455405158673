import '../libs/tilt/vanilla-tilt.min.js';

export const tiltAnimation = () => {
    const elements = document.querySelectorAll('.js-tilt');
    VanillaTilt.init(elements, {
		  max: 25,
      speed: 500
	});
}


