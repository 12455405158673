import { Swiper, Autoplay } from 'swiper';

export const initAboutSlider = timer => {
    Swiper.use([Autoplay]);
    const startProgressBar = () => {
      // apply keyframe animation
      $(".slide-progress").css({
        width: "100%",
        transition: `width ${timer}ms linear`
      });
    }
    const resetProgressBar = () => {
      $(".slide-progress").css({
        width: 0,
        transition: "width 0s"
      });
    }
    const aboutCarousel = new Swiper(".about .swiper-container", {
      spaceBetween: 0,
      slidesPerView: 1,
      autoHeight: true,
      loop: true,
      autoplay: {
        delay: timer,
        disableOnInteraction: false
      },
      on: {
        init: startProgressBar,
        slideChange: resetProgressBar,
        transitionEnd: startProgressBar,
      },
      breakpoints: {
        768: {
          autoHeight: false
        }
      }
    });
    $(".about .next").click(function() {
      aboutCarousel.slideNext(); 
    });
    $(".about .prev").click(function() {
      aboutCarousel.slidePrev();
    });
};