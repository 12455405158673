import { preventEvent }  from '../utils/etc';

const covidWindowHandler = () => {
    const covidElement = document.querySelector('.covid-window');
    const covidCloseBtn = document.querySelector('.covid-window__close');
    if(covidElement && covidCloseBtn) {
        !!localStorage.getItem('covidModalActive') ? '' : localStorage.setItem('covidModalActive', 'true')
        if(localStorage.getItem('covidModalActive') == 'true') {
            covidElement.classList.add('active');
        } else {
            covidElement.classList.add('active', 'hidden');
        }
        const toggleWindowState = (e) => {
            preventEvent(e);
            if(covidElement.classList.contains('active') && !covidElement.classList.contains('hidden')) {
                if(!!localStorage.getItem('covidModalActive')) {
                    localStorage.setItem('covidModalActive', 'false');
                    covidElement.classList.add('hidden');
                }
            } else {
                localStorage.setItem('covidModalActive', 'true');
                covidElement.classList.remove('hidden');
            }
        };
        covidCloseBtn.addEventListener('click', toggleWindowState);
    }
};

export {
    covidWindowHandler
};