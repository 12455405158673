import { screenAnimation, mertisAnimation, textAnimation, footerMobileAnimation, footerTextAnimation, footerLinksAnimation } from './animations/all-animation';
import { covidWindowHandler } from './animations/covid-window';
import { tiltAnimation } from './animations/tilt-parallax';
import { initAboutSlider } from './home/about-slider';
import { mainSharesSlider } from './components/slider/main-shares';
// home scripts initialization
document.addEventListener("DOMContentLoaded", function(event) {

    window.onload = function() {
        screenAnimation();
        mertisAnimation();
        if($(window).width() > 1024) {
            tiltAnimation();
        }
        initAboutSlider(60000);
        mainSharesSlider();
        textAnimation();
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                covidWindowHandler();
            });
        }, 3000)
        footerMobileAnimation();
        footerTextAnimation();
        footerLinksAnimation();
    }
});