//GSAP Modules
import { gsap } from '../libs/gsap/gsap.min';
import SplitText from '../libs/gsap/SplitText.min';
import ScrollMagic from '../libs/gsap/ScrollMagic.min';


const screenAnimation = () => {
    const screenController = new ScrollMagic.Controller();
    const fullBgTween = gsap.fromTo(    
        $('.first-screen-bg'), .9,
        {scale: 3, force3D:false}, 
        {scale:1, force3D:false},

    );
    fullBgTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '.first-screen-bg', 
        offset: 150
    })
    .on("enter", function() {
        fullBgTween.play();
    })
    .addTo(screenController)
    
    const screenTween = gsap.fromTo(
        $('.dashed-line'), .9, 
        {scaleY: 0, autoAlpha:0, force3D:false}, 
        {scaleY:1, autoAlpha:1, delay: .5, ease:Elastic.easeInOut, force3D:false, stagger: 0.2}, 
    );
    screenTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '.first-screen-bg',
        offset: 150
    })
    .on('enter', function() {
        screenTween.play();
    })
    .addTo(screenController)

    const circleTween = gsap.fromTo(
        $('.dashed-circle'), 1.4, 
        {scale: 0, autoAlpha:0, force3D:true}, 
        {scale:1, autoAlpha:1, delay: .5, ease:Elastic.easeInOut, force3D:true, stagger: 0.2}
    );
    circleTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '.first-screen-bg', 
        offset: 150
    })
    .on('enter', function() {
        circleTween.play();
    })
    .addTo(screenController)

    const gradientTween = gsap.fromTo(
        $('.dashed-gradient'), 1.9, 
        {scaleX: 0, autoAlpha:0, force3D:false}, 
        {scaleX:1, autoAlpha:1, delay: .3, ease:Expo.easeInOut, force3D:false}
    );
    gradientTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '.first-screen-bg',
        offset: 150
    })
    .on('enter', function() {
        gradientTween.play();
    })
    .addTo(screenController)

    const ovalTween = gsap.fromTo(
        ".oval-right", 5,
        {
            scale: 1,
            autoAlpha: .75,
            force3D:false,
            delay: 5,
            ease: Expo.easeNone
        }, 
        {
            scale: 1.25,
            autoAlpha: 1,
            repeat: -1,
            delay: 5,
            ease: Expo.easeOut,
            yoyo: true,
            force3D:false
        }
    );
    ovalTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '#screenbg',
        offset: 150
    })
    .on('enter', function() {
        ovalTween.play();
    })
    .addTo(screenController)
    const ovalLeftTween = gsap.fromTo(    
        ".oval-left", 8,
        {
            scale: 1,
            autoAlpha: .75,
            force3D:false,
            delay: 8,
            ease: Expo.easeNone
        }, 
        {
            scale: 1.2,
            autoAlpha: 1, 
            delay: 8,
            ease: Expo.easeOut,
            repeat: -1,
            yoyo: true,
            force3D:false
        }
    );
    ovalLeftTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '#screenbg',
        offset: 150
    })
    .on('enter', function() {
        ovalLeftTween.play();
    })
    .addTo(screenController)     
};

const mertisAnimation = () => {
    const controller = new ScrollMagic.Controller();
    const tween = gsap.fromTo(
        $('.mertis'), 2, 
        {scale: 0, autoAlpha:0, force3D:false}, 
        {scale:1, autoAlpha:1, visibility: 'visible', ease:Elastic.easeOut, force3D:false, stagger: 0.2}
    );
    tween.pause();
    let offset;
    if($(window).width() <= 991) {
        offset = -150
    }
    else if ($(window).width() <= 1024 && $(window).width() > 991) {
        offset = -200
    } else {
        offset = -250
    }
    new ScrollMagic.Scene({
        triggerElement: '#mertislist', 
        offset: offset,
        reverse: false
    })
    .on('enter', function() {
        tween.play();
    })
    .addTo(controller)
};

const textAnimation = () => {
    const text = $('.text-animation'),
          split = new SplitText(text, {type:"words,chars"}),
          chars = split.chars,
          textController = new ScrollMagic.Controller();
    gsap.set(text, {perspective: 200});
    const textTween = gsap.from(
        chars, .7, { autoAlpha:0, delay: .8, scale:0, y:80, rotationX: 100, transformOrigin:"0% 50% -50",  ease:Back.easeOut, force3D: false, stagger: 0.02}, 
    );
    textTween.pause();
    const textScene = new ScrollMagic.Scene({
        triggerElement: '.preview',
        offset: 150
    })
    .on("enter", function() {
        textTween.play();
    })
    .addTo(textController);
};
const footerTextAnimation = () => {
    const footerTextController = new ScrollMagic.Controller();
    const footerTextTween = gsap.from(
        $('.footer-apps__center'), 1,
        {y: -200, ease: "expo.out", force3D:false, delay: 1.1}
    );
    footerTextTween.pause();
    new ScrollMagic.Scene({
        triggerElement: 'footer.footer',
        offset: -300,
        reverse: false
    })
    .on('enter', function() {
        footerTextTween.play();
    })
    .addTo(footerTextController)
};
const footerMobileAnimation = () => {
    const footerController = new ScrollMagic.Controller();
    const footerTween = gsap.from(
        $('.footer-apps__left > img'), 1,
        {y: 200, ease: "expo.out", force3D:false, delay: 1}
    );
    footerTween.pause();
    new ScrollMagic.Scene({
        triggerElement: 'footer.footer',
        offset: -300,
        reverse: false
    })
        .on('enter', function() {
            footerTween.play();
        })
        .addTo(footerController)
};
const footerLinksAnimation = () => {
    const footerLinksController = new ScrollMagic.Controller();
    const footerLinksTween = gsap.from(
        $(".footer-apps__right > a"), .8,
        {
            scale: 0,
            delay: .6,
            ease: Back.easeOut,
            force3D:true,
            stagger: .2
        }
    );
    footerLinksTween.pause();
    new ScrollMagic.Scene({
        triggerElement: '.footer',
        offset: -300,
        reverse: false
    })
        .on('enter', function() {
            footerLinksTween.play();
        })
        .addTo(footerLinksController)
}

export { screenAnimation, mertisAnimation, textAnimation, footerMobileAnimation, footerTextAnimation, footerLinksAnimation };
